import React, { useState, } from "react";
import { Box, Button, FormControl, MenuItem, Select, Stack } from "@mui/material";
import toast, { Toaster } from "react-hot-toast";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import TableNlp from "../../components/table";
import { ChatBot } from "../../components/ChatBot";
import { Treinamento } from "../../components/Treinamento";
import { NlpCriarNovoModelo, NlpModelos, NlpModelosInfo } from "../../services";
import { useEffect } from "react";

const HomePage = () => {
  const [
    modeloIdentificadoTreinoIdentificado,
    setModeloIdentificadoTreinoIdentificado,
  ] = useState("");
  const [modelosDisponivel, setModelosDisponivel] = useState([]);
  const [modeloChatTreino, setModeloChatTreino] = useState("");
  const [modeloTreino, setModeloTreino] = useState([]);
  const [atualizar, setAtualizar] = useState(false);
  const [nomeModelo, setNomeModelo] = useState("");
  const [telaInicial, setTelaInicial] = useState(false);
  const [infoModelo, setInfoModelo] = useState([]);
  const [palavrasChaves, setPalavrasChaves] = useState([]);
  const [respostasModelo, setRespostasModelo] = useState([]);

  const indentificador = modeloIdentificadoTreinoIdentificado !== null ? modeloIdentificadoTreinoIdentificado.toLowerCase() : ""

  const dadosFiltrados = infoModelo.filter((item) => item.intent.toLowerCase().includes(indentificador))


  const atualizarPagina = () => {
    setAtualizar(!atualizar)
  }
  const handleChangeNomeModelo = (event) => {
    setNomeModelo(event.target.value);
    // atualizarPagina()
  };
  const handleChangeModeloTreino = (event) => {
    setModeloTreino(event.target.value);
    // atualizarPagina()
  };
  const telaDeTreinamento = () => {
    if (!telaInicial && modeloTreino) {
      buscandoDadosApi()
      setTelaInicial(true);


    } else {
      toast("Escolha um modelo", {
        icon: "😥",
        style: {
          borderRadius: "10px",
          background: "#eb4034",
          color: "#fff",
        },
      });
    }
  };
  const postCriarNovoModelo = async () => {
    await NlpCriarNovoModelo(nomeModelo);
    setNomeModelo("");
    atualizarPagina()
    toast("Modelo criado com sucesso!", {
      icon: "🎉",
      style: {
        borderRadius: "10px",
        background: "#088229",
        color: "#fff",
      },
    });
  };
  const buscandoDadosApi = async () => {
    try {
      const modelos = await NlpModelos();
      setModelosDisponivel(modelos);
      setModeloChatTreino(modelos[0]);

      const responseModelo = await NlpModelosInfo(modeloTreino);

      setInfoModelo(responseModelo.tabela)
      setRespostasModelo(responseModelo.respostas);
      setPalavrasChaves(responseModelo.palavrasChaves);

    } catch (error) {
      console.error("Erro ao buscar dados:", error);
    } finally {
      // setTelaInicial(true);


    }
  }

  useEffect(() => {
    (async () => {
      try {
        const modelos = await NlpModelos();
        setModelosDisponivel(modelos);
        setModeloChatTreino(modelos[0]);
      } catch (err) {
        console.log(err)
      }
    })()
  }, [])



   useEffect(() => {

     (async () => {
       await buscandoDadosApi()

     })()

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [atualizar])





  return (
    <Stack direction="row" justifyContent="center" alignItems="flex-start"
    >
      <Toaster
        position="top-center"
        reverseOrder={false}
      />

      <Box width={"100%"} height={"100vh"} sx={{ color: "#fff", padding: "10px 2px 2px 100px" }}>

        {telaInicial ? <>

          <ArrowBackIosNewIcon onClick={() => { setTelaInicial(false) }} style={{ cursor: 'pointer', color: "#fff", marginTop: 5, fontSize: 35 }} />

          <h1 style={{ textAlign: 'center' }}> Treinamento de intenção, pergunta e resposta </h1>

          <Stack justifyContent={'center'} alignItems={'center'} sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 0,
          }}>

            <div style={{ margin: 0, width: '80vw' }}>

              <h4 style={{ backgroundColor: "#128C7E", padding: "6px 6px 10px 30px", borderTopRightRadius: 5, borderTopLeftRadius: 5, margin: 0 }}> Modelo ⇛ {modeloTreino}</h4>

              <Treinamento
                modeloIdentificadoTreinoIdentificado={modeloIdentificadoTreinoIdentificado}
                setModeloIdentificadoTreinoIdentificado={setModeloIdentificadoTreinoIdentificado}
                atualizarPagina={atualizarPagina}
                palavrasChaves={palavrasChaves}
                modeloTreino={modeloTreino}
                buscandoDadosApi={buscandoDadosApi}
                infoModelo={infoModelo}
              />

              <TableNlp
                key={dadosFiltrados}
                respostasIntencao={respostasModelo}
                infoModelo={dadosFiltrados}
                modeloTreino={modeloTreino}

                atualizarPagina={atualizarPagina
                }
              />


            </div>
          </Stack>
          <Stack direction="column" width={"auto"}
          >
          </Stack>
        </> :

          <Stack justifyContent={'center'} alignItems={'center'} marginTop={10} sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '90vh',
            margin: 0,
          }}>

            <h1> Escolha o Modelo da IA </h1>

            <Stack justifyContent={'center'} alignItems={"center"} direction={'column'} spacing={2} margin={5} >

              <FormControl sx={{ m: 1, minWidth: 120, width: '30vw' }} size="small">
                <Select
                  value={modeloTreino}
                  onChange={handleChangeModeloTreino}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Without label' }}
                  sx={{ backgroundColor: 'white', color: 'black', border: 'none' }}
                >
                  <MenuItem value="" disabled>
                    Escolha um modelo
                  </MenuItem>

                  {modelosDisponivel && modelosDisponivel.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <div style={{ marginTop: 30 }}>

                <input
                  type="text"
                  placeholder="Nome do modelo "
                  value={nomeModelo}
                  onChange={handleChangeNomeModelo}
                  style={{ padding: '10px', marginRight: '5px', outline: 'none', borderRadius: 5 }}
                />
                <Button style={{ padding: '10px', backgroundColor: '#128C7E', color: "#fff", fontSize: 10, fontWeight: 600 }} size="small" onClick={() => { postCriarNovoModelo() }}> Novo  </Button>
              </div>
            </Stack>
            <Button style={{ padding: '10px', backgroundColor: '#128C7E', color: "#fff", fontSize: 10, fontWeight: 600, width: 180 }} size="small" onClick={() => { telaDeTreinamento() }}> Avançar   </Button>
          </Stack>
        }
      </Box>
      {telaInicial && modeloTreino && <ChatBot modeloTreino={modeloTreino} />}
    </Stack>
  );
};
export default HomePage;
