import { IconButton, Toolbar, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';


const size = {
  width: 1300,
  height: 700,

};


export default function ModalEstatistica({ openEstatistica, setOpenEstatistica, palavrasChaves, infoModelo }) {
  const handleClose = () => {
    setOpenEstatistica(false);
  };

  const data = palavrasChaves.map((palavra) => {
    const quantidade = infoModelo.reduce((total, item) => {
      return total + (item.intent === palavra ? 1 : 0);
    }, 0);

    return { value: quantidade, label: palavra };
  });

  return (
    <React.Fragment >

      <Dialog
        open={openEstatistica}
        onClose={handleClose}
        maxWidth={"xl"}
        fullWidth={true}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();


          },
        }}
      >
        <Toolbar >

          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Dados
          </Typography>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon sx={{ color: "#ed0c57" }} />
          </IconButton>
        </Toolbar>
        <DialogContent style={{ backgroundColor: '#f1f1f1' }}
        >

          {data.length > 0 && <PieChart
            series={[
              {
                arcLabel: (item) => `${item.label} (${item.value})`,
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
                data,
                innerRadius: 200,
                cx: 400,
                cy: 350,


              },
            ]}
            sx={{
              [`& .${pieArcLabelClasses.root}`]: {
                fill: 'white',
                fontSize: 13,
                fontWeight: 'bold',
              },
            }}
            {...size}
          />}


        </DialogContent>

      </Dialog>
    </React.Fragment>
  );
}