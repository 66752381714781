export const goToHomePage = (navigate) => {
    navigate("/")
  }
export const goToVoltar = (navigate) => {
    navigate(-1)
  }
export const goToTeste = (navigate) => {
    navigate("/TestePage")
  }


