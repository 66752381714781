import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useEffect, useState } from 'react';
import { NlpEditarResposta } from '../../services';
import toast from 'react-hot-toast';

export default function ModalEditarResposta({ openEditar, setOpenEditar, editarRespostaAtual, modeloTreino, identificado,atualizarPagina }) {



  const handleClose = () => {
    setOpenEditar(false);
  };
  const [resposta, setRestposta] = useState(editarRespostaAtual)

  useEffect(() => {
    setRestposta(editarRespostaAtual)
  }, [editarRespostaAtual])


  const handleChange = (event) => {
    setRestposta(event.target.value);
  };

  const EditarResposta = async () => {

    try {
      await NlpEditarResposta(modeloTreino, resposta, identificado)
            setRestposta(editarRespostaAtual)
      toast.success('Resposta editada com sucesso')
      setOpenEditar(false)
    } catch (err) {
      toast.error('deu ruim')

    } finally{
      atualizarPagina()

    }

  }

  return (
    <React.Fragment>

      <Dialog
        open={openEditar}
        onClose={handleClose}

        fullWidth={true}
        PaperProps={{
          component: 'form',
          onSubmit: (event) => {
            event.preventDefault();
            // const formData = new FormData(event.currentTarget);
            // const formJson = Object.fromEntries(formData.entries());
            // const nome = formJson.nome;

            // alert('Foi mudado o nome ', editarRespostaAtual, ' para ', nome)
            EditarResposta()

          },
        }}
      >
        <DialogTitle>Editar Resposta </DialogTitle>
        <DialogContent>

            <TextField
            autoFocus
            required

            margin="dense"
            id="nome"
            name="nome"
            label="Edite a resposta"
            value={resposta}
            onChange={handleChange}
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={handleClose}>Cancel</Button>
          <Button variant='contained' color='success' type="submit">Confirmar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}