import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DeleteForever } from "@mui/icons-material";
import { NlpDeleteTreinoResposta } from "../../services";
import { useState } from "react";
import EditIcon from '@mui/icons-material/Edit';
import CarregandoPagina from "../CarregandoPagina";
import ModalEditarResposta from "../ModalEditarResposta";
import toast from "react-hot-toast";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#128C7E",
    color: theme.palette.common.white,
    position: "sticky",
    top: 0,
    zIndex: 1,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableContainer = styled(TableContainer)({
  height: "70%", // Defina a altura máxima desejada
  overflowY: "auto", // Adicione rolagem vertical
});

export default function TableNlp({
  respostasIntencao,
  modeloTreino,
  infoModelo,
  atualizarPagina


}) {
  const [openCarregandoPagina, setOpenCarregandoPagina] = useState(false);
  const [openEditar, setOpenEditar] = useState(false);
  const [editarRespostaAtual, setEditarRespostaAtual] = useState([]);
  const [editarIntencao, setEditarIntencao] = useState('');


  const excluiritencao = async (row) => {
    if (modeloTreino && row.utterance && row.intent) {
      try {
        setOpenCarregandoPagina(true)
        await NlpDeleteTreinoResposta(modeloTreino, row.utterance, row.intent);
        toast("Excluido Com sucesso!", {
          icon: "✅",
          style: {
            borderRadius: "10px",
            background: "#088229",
            color: "#fff",
          },
        });


      } catch (err) {
        toast("Deu algum erro!", {
          icon: "❌",
          style: {
            borderRadius: "10px",
            background: "#f54272",
            color: "#fff",
          },
        });
      } finally {
        setOpenCarregandoPagina(false);
        atualizarPagina()
      }
    }
  };

  const pegandoResposta = (row) => {
    try {
      if (row) {
        const filtrado = respostasIntencao &&
          Object.keys(respostasIntencao).find((item) => {
            return row === item;
          });

        if (filtrado) {
          const respostasArray = respostasIntencao[filtrado];

          if (respostasArray && respostasArray.length > 0) {
            const respostasString = respostasArray.map(resposta => resposta.answer).join(', ');
            return respostasString;
          }
        }
      }
    } catch (err) {

      return " ";

    }
  };

  const EdiarResposta = (respostas, intencao) => {
    setEditarIntencao(intencao)
    setEditarRespostaAtual(respostas)
    setOpenEditar(true)
  }

  return (
    <StyledTableContainer
      style={{
        borderRadius: 0,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5,
        height: "68vh",
        position: 'relative', // Adiciona position relative ao container
      }}
      component={Paper}
    >
      <CarregandoPagina openCarregandoPagina={openCarregandoPagina} />
      <ModalEditarResposta

        atualizarPagina={atualizarPagina}

        openEditar={openEditar} setOpenEditar={setOpenEditar} editarRespostaAtual={editarRespostaAtual} modeloTreino={modeloTreino} identificado={editarIntencao} />
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">Identificador</StyledTableCell>
            {/* <StyledTableCell align="center">Não sei o que é</StyledTableCell> */}
            <StyledTableCell align="center">Frase Treinada</StyledTableCell>
            <StyledTableCell align="center"> Resposta </StyledTableCell>
            <StyledTableCell align="center"> Excluir </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {infoModelo &&
            infoModelo.map((row) => (
              <StyledTableRow key={`${row.intent}`}>
                <StyledTableCell
                  align="center"
                  style={{ fontWeight: 600, color: "#095e48" }}
                >
                  {row.intent}
                </StyledTableCell>
                {/* <StyledTableCell align="center">{row.domain}</StyledTableCell> */}
                <StyledTableCell align="center">
                  {row.utterance}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {
                    respostasIntencao && pegandoResposta(row?.intent)
                  }
                  <EditIcon sx={{ marginLeft: 1, width: 18, cursor: 'pointer' }} onClick={() => { EdiarResposta(pegandoResposta(row?.intent), row?.intent) }} />

                </StyledTableCell>
                <StyledTableCell align="center" >
                  <DeleteForever
                    style={{
                      backgroundColor: "#b80000",
                      color: "#f1f1f1",
                      padding: 4,
                      width: 30,
                      height: 30,
                      borderRadius: "100%",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      row && excluiritencao(row);
                    }}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
        </TableBody>
        <TableBody>

          <TableRow style={{
            backgroundColor: '#128C7E',
            position: "sticky",
            bottom: 0,
            zIndex: 1,
          }}>
            <TableCell style={{ fontWeight: 600, color: "#fff" }}
              colSpan={2}>Total</TableCell>

            <TableCell style={{ fontWeight: 600, color: "#fff" }}
              align="right"> </TableCell>

            <TableCell style={{ fontWeight: 600, color: "#fff" }}
              align="right">{infoModelo.length} </TableCell>
          </TableRow>

        </TableBody>

      </Table>

    </StyledTableContainer>
  );
}
