import { Avatar, Box, Stack, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import fundo from "../../assets/fundo.jpg";
import { NlpConversa } from "../../services";
import toast from "react-hot-toast";
import robo from '../../assets/robo.png'
import CancelIcon from '@mui/icons-material/Cancel';
import ReactJson from "react-json-view";
import HelpIcon from '@mui/icons-material/Help';

export const ChatBot = ({ modeloTreino }) => {
  const [chatOpen, setChatOpen] = useState(false)
  const [chat, setChat] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [UltimaResptaApi, setUltimaRespostaApi] = useState({})

  const [atualizar, setAtualizar] = useState(false)

  const [resumoOpen, setResumoOpen] = useState(true)

  const chatContainerRef = useRef();

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  }, [chat]);



  const chatOpenOnclick = () => {
    setChatOpen(!chatOpen)
  }


  const resumoOpenOnclick = () => {
    setResumoOpen(!resumoOpen)
  }


  const handleUserInput = (e) => {
    setUserInput(e.target.value);
  };

  const sendMessage = async () => {
    if (userInput.trim() !== "") {
      // Enviar a mensagem do usuário para a API e obter a resposta do bot

      if (!modeloTreino) {

        toast('Escolha um modelo',
          {
            icon: '😥',
            style: {
              borderRadius: '10px',
              background: '#eb4034',
              color: '#fff',
            },
          }
        );

      } else {

        const response = await NlpConversa(userInput, modeloTreino);

        setUltimaRespostaApi(response)

        // Atualizar o estado do chat com a mensagem do usuário e a resposta do bot
        setChat((prevChat) => [
          ...prevChat,
          { text: userInput, isUser: true },
          { text: response.responsta, isUser: false },
        ]);

        // Limpar o campo de entrada após obter a resposta
        setUserInput("");
        setAtualizar(!atualizar)
      }

    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      sendMessage();
      setAtualizar(!atualizar)

    }
  };


  return (
    <div>
      {
        chatOpen ? <div
          style={{
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 400,
            height: 500,
            borderRadius: '2%',
            // backgroundColor: '#f1f1f1',
            position: 'fixed',
            bottom: 20,
            right: 20,
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            transition: "opacity 0.5s ease-in-out", // Adiciona transição suave
            background: `url(${ resumoOpen ? fundo : '' })`,
            backgroundColor: `${resumoOpen ? ''  : '#222'}`
          }}
        >
          <div
            style={{
              display: 'flex',
              width: 400,
              height: 50,
              borderRadius: '2%',
              backgroundColor: '#075E54',
              position: 'absolute',
              top: 0,
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>
              <Avatar alt="robô" src={robo} style={{ marginRight: 10, width: 30, height: 30 }} />
              <Typography color={'#f1f1f1'} fontWeight={600}>
                Robô
              </Typography>
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 20 }}>

              <HelpIcon style={{ color: "#f1f1f1", cursor: 'pointer', marginRight: 5 }} onClick={() => {resumoOpenOnclick()}} />

              <CancelIcon style={{ color: "#f1f1f1", cursor: 'pointer', marginRight: 20 }} onClick={() => {chatOpenOnclick()}}

              />

            </div>


          </div>


          <Box
            width={"100%"}
            height={"80%"}
            sx={{ position: "absolute", backgroundSize: "cover", overflowY: "auto", borderRadius: 2 }}
          >
            <Stack direction={'column'} justifyContent="flex-end" alignItems="flex-end" height="100%"  padding={2}
            >
              {resumoOpen ? <div

                ref={chatContainerRef}
                style={{ overflowY: "auto", display: "flex", flexDirection: "column", width: '100%'      }}>
                {chat.map((message, index) => {
                  const erro =  "Não entendi, preciso de mais treinamento"
                  
                  return  <div
                  key={index}
                  style={{
                    backgroundColor: (message.isUser ? "#4CAF50" : message.text === erro ? "#e0ca02" :  "#f1f1f1" ),
                    color: message.isUser ? "white" :  "#000",
                    padding: "10px",
                    borderRadius: "8px",
                    marginBottom: "5px",
                    marginLeft: 10,
                    marginRight: 20,
                    marginBottom: 10,
                    maxWidth: "70%",
                    textAlign: message.isUser ? "right" : "left",
                    alignSelf: message.isUser ? "flex-end" : "flex-start",
            
                  }}
                >
                  { message.text}
                </div>
                })}

              </div> : <div style={{ overflowY: 'auto'}}>

                <h2 style={{ color: "#cf3", height: "auto" }}> Resumo </h2>

                <hr />

                <p style={{ color: '#fff', display: 'flex', alignItems: 'center', height: 10 }}> Message = <p style={{ color: "#ff8300", marginLeft: 10 }}> {UltimaResptaApi?.message} </p> </p>

                <p style={{ color: '#fff', display: 'flex', alignItems: 'center', height: 10 }}> Responsta = <p style={{ color: "#ff8300", marginLeft: 10 }}> {UltimaResptaApi?.responsta} </p> </p>

                <p style={{ color: '#fff', display: 'flex', alignItems: 'center', height: 10 }}> Certeza  = <p style={{ color: "#ff8300", marginLeft: 10 }}> {UltimaResptaApi?.dados?.score} </p> </p>

                <p style={{ color: '#fff', display: 'flex', alignItems: 'center', height: 10 }}> Intenção = <p style={{ color: "#ff8300", marginLeft: 10 }}> {UltimaResptaApi?.dados?.intent} </p> </p>

                <p style={{ color: '#fff', marginTop: 30, marginBottom: 20 }}> Tudo que api retorna </p>
                <ReactJson src={UltimaResptaApi} theme="ocean" displayDataTypes={false} indentWidth={4} collapsed={true} />

                <p style={{ color: '#fff' }}> Classifications </p>
                <ReactJson src={UltimaResptaApi?.dados?.classifications} theme="ocean" displayDataTypes={false} indentWidth={4} collapsed={true} />

                <p style={{ color: '#fff' }}> Answers </p>
                <ReactJson src={UltimaResptaApi?.dados?.answers} theme="ocean" displayDataTypes={false} indentWidth={4} collapsed={true} />

              </div>

              }


            </Stack>
          </Box>


          {/* Input para o usuário enviar mensagens */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              marginTop: "auto",
              borderTop: "1px solid #ccc",
              padding: "10px",
              backgroundColor: "#f1f1f1",
              borderRadius: 4
            }}
          >
            <input
              type="text"
              style={{
                flex: 1,
                padding: "8px",
                border: "1px solid #ccc",
                borderRadius: "5px",
                fontSize: "16px",
                outline: 'none'
              }}
              placeholder="Digite sua mensagem..."
              value={userInput}
              onChange={handleUserInput}
              onKeyDown={handleKeyDown}
            />
            <button
              onClick={sendMessage}
              style={{
                marginLeft: "10px",
                padding: "8px",
                fontSize: "16px",
                borderRadius: "5px",
                background: "#128C7E",
                color: "white",
                border: "none",
                cursor: "pointer",
              }}
            >
              Enviar
            </button>
            <button
              onClick={() => { setChat([]) }}
              style={{
                marginLeft: "10px",
                padding: "8px",
                fontSize: "16px",
                borderRadius: "5px",
                background: "#cc281d",
                color: "white",
                border: "none",
                cursor: "pointer",
              }}
            >
              Limpa
            </button>

          </div>




        </div> : <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 70,
            height: 70,
            borderRadius: '50%',
            backgroundColor: '#128C7E',
            position: 'fixed',
            bottom: 20,
            right: 20,
            cursor: 'pointer',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            transition: "opacity 0.5s ease-in-out", // Adiciona transição suave


          }}
          onClick={() => { chatOpenOnclick() }}

        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            fill="currentColor"
            className="bi bi-robot"
            viewBox="0 0 16 16"
            style={{ color: '#f1f1f1' }}

          >
            <path d="M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5M3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.6 26.6 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.93.93 0 0 1-.765.935c-.845.147-2.34.346-4.235.346s-3.39-.2-4.235-.346A.93.93 0 0 1 3 9.219zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a25 25 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25 25 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135" />
            <path d="M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2zM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5" />
          </svg>
        </div>

      }
    </div>
  );
};
