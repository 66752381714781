import axios from "axios";
import { BASE_URL } from "../constants/BASE_URL.js";

export const NlpConversa = async (msg, modeloChat) => {
	try {
	  const body = { menssagem: msg, modelo: modeloChat };

	  const response = await axios.post(`${BASE_URL}/nlp/conversar`, body);
	  return response.data;
	} catch (error) {
	  console.error(error.response);
	}
  };
  

  export const NlpModelos = async () => {
	try {
	  const response = await axios.get(`${BASE_URL}/nlp/models`);

		console.log(response)

	  return response.data;
	} catch (error) {
	  console.error(error.response);
	}
  };
  

  export const NlpTreinoResposta = async (modeloChat, msg, identificado) => {
	try {
	  const body = { modelo: modeloChat, menssagem: msg, identificado };

	  const response = await axios.post(`${BASE_URL}/nlp/treino/resposta`, body);
	  return response.data;
	} catch (error) {
	  console.error(error.response);
	}
  };

  export const NlpDeleteTreinoResposta = async (modeloChat, msg, identificado) => {
	try {
	  const body = { modelo: modeloChat, menssagem: msg, identificado };

	  const response = await axios.post(`${BASE_URL}/nlp/delete/treino/resposta`, body);
	  return response.data;
	} catch (error) {
	  console.error(error.response);
	}
  };

  export const NlpEditarResposta = async (modeloChat, msg, identificado) => {
	try {
	  const body = { modelo: modeloChat, menssagem: msg, identificado };


	  const response = await axios.patch(`${BASE_URL}/nlp/editar/treino/resposta`, body);
	  return response.data;
	} catch (error) {
	  console.error(error.response);
	}
  };
  
  
  export const NlpLimpaRespostas = async (modeloChat) => {
	try {
	  const body = { modelo: modeloChat};

	  const response = await axios.post(`${BASE_URL}/nlp/limpa/treino/resposta`, body);
	  return response.data;
	} catch (error) {
	  console.error(error.response);
	}
  };
  
  

  export const NlpTreinoIdentificado = async ( modeloChat, msg, identificado) => {
	try {
	  const body = { modelo: modeloChat,  menssagem: msg, identificado };
	  await axios.post(`${BASE_URL}/nlp/treino/identificado`, body);
	
	} catch (error) {
	  console.error(error.response);
	}
  };


  export const NlpModelosInfo = async (modelo) => {
	try {
	  const response = await axios.get(`${BASE_URL}/nlp/models/info/${modelo}`);
	  return response.data;
	} catch (error) {
	  console.error(error.response);
	}
  };

  export const NlpCriarNovoModelo = async (modelo) => {
	try {
	  const response = await axios.post(`${BASE_URL}/nlp/models/criar/${modelo}`);
	  return response.data;
	} catch (error) {
	  console.error(error.response);
	}
  };
  
  