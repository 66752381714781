import React from "react";
import { Router } from "./routes/Routes"
import "./styles/globals.css"


function App() {
  return (
      <Router/>

  );
}
export default App;
