import React, { useState } from "react";
import { Autocomplete, Button, Stack, TextField, Tooltip } from "@mui/material";
import { NlpLimpaRespostas, NlpTreinoIdentificado, NlpTreinoResposta } from "../../services/index";
import toast from "react-hot-toast";
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import QueryStatsSharpIcon from '@mui/icons-material/QueryStatsSharp';
import ModalEstatistica from "./ModalEstatistica";


export const Treinamento = ({ infoModelo, modeloTreino, buscandoDadosApi, modeloIdentificadoTreinoIdentificado, setModeloIdentificadoTreinoIdentificado, palavrasChaves, atualizarPagina }) => {

  const [modeloRespostaTreinoResposta, setModeloRespostaTreinoResposta] = useState('')
  const [modeloIdentificadoTreinoResposta, setModeloIdentificadoTreinoResposta] = useState('')
  const [openEstatistica, setOpenEstatistica] = useState(false)

  const handleChangeModeloRespostaTreinoResposta = (event) => {
    setModeloRespostaTreinoResposta(event.target.value);
  };
  const handleChangeModeloIdentificadoTreinoResposta = (event) => {
    setModeloIdentificadoTreinoResposta(event.target.value)
  };
  const handleChangeModeloIdentificadoTreinoIdentificado = (event, newValue) => {
    setModeloIdentificadoTreinoIdentificado(newValue);
  }
  const postNlpTreinoResposta = async (modeloTreino, modeloRespostaTreinoResposta, modeloRespostaTreinoIdentificado) => {

    try {

      await NlpTreinoResposta(modeloTreino, modeloRespostaTreinoResposta, modeloRespostaTreinoIdentificado)
      setModeloRespostaTreinoResposta('')
      toast('Resposta Treinada com sucesso!',
        {
          icon: '🎉',
          style: {
            borderRadius: '10px',
            background: '#088229',
            color: '#fff',
          },
        }
      );

    } catch (err) {
      toast('Deu algum error!',
        {
          icon: '😥',
          style: {
            borderRadius: '10px',
            background: '#eb4034',
            color: '#fff',
          },
        }
      );
    } finally {
      atualizarPagina()
    }
  }
  const postNlpTreinoIndentificado = async (modeloTreino, modeloIdentificadoTreinoResposta, modeloIdentificadoTreinoIdentificado) => {

    try {

      await NlpTreinoIdentificado(modeloTreino, modeloIdentificadoTreinoResposta, modeloIdentificadoTreinoIdentificado)

      setModeloIdentificadoTreinoResposta('')

      toast('Indentificado Treinado com sucesso!',
        {
          icon: '🎉',
          style: {
            borderRadius: '10px',
            background: '#088229',
            color: '#fff',
          },
        }
      );
    } catch (err) {
      toast('Deu algum error!',
        {
          icon: '😥',
          style: {
            borderRadius: '10px',
            background: '#eb4034',
            color: '#fff',
          },
        }
      )
    } finally {
      atualizarPagina()
    }
  }
  const handleKeyDownPergunta = async (e) => {
    if (e.key === "Enter") {

      try {
        if (modeloIdentificadoTreinoIdentificado) {
          await postNlpTreinoIndentificado(modeloTreino, modeloIdentificadoTreinoResposta, modeloIdentificadoTreinoIdentificado)
        }

      } catch (err) {
        if (!modeloIdentificadoTreinoIdentificado) {
          toast('Escolha uma intenção',
            {
              icon: '😥',
              style: {
                borderRadius: '10px',
                background: '#eb4034',
                color: '#fff',
              },
            }
          );
        }
        if (!modeloIdentificadoTreinoResposta) {
          toast('Digite a pergunta',
            {
              icon: '😥',
              style: {
                borderRadius: '10px',
                background: '#eb4034',
                color: '#fff',
              },
            }
          );
        }


      } finally {
        // atualizarPagina()
      }

    }

  }
  const handleKeyDownResposta = async (e) => {
    if (e.key === "Enter") {


      try {

        if (modeloIdentificadoTreinoIdentificado) {
          await postNlpTreinoResposta(modeloTreino, modeloRespostaTreinoResposta, modeloIdentificadoTreinoIdentificado)
        }
      } catch (err) {

        if (!modeloIdentificadoTreinoIdentificado) {
          toast('Escolha uma intenção',
            {
              icon: '😥',
              style: {
                borderRadius: '10px',
                background: '#eb4034',
                color: '#fff',
              },
            }
          );
        }

        if (!modeloRespostaTreinoResposta) {
          toast('Digite a resposta',
            {
              icon: '😥',
              style: {
                borderRadius: '10px',
                background: '#eb4034',
                color: '#fff',
              },
            }
          );
        }

      } finally {
        // atualizarPagina()
      }
    }
  };
  const apiTreinaPergunta = async () => {
    try {
      await postNlpTreinoIndentificado(modeloTreino, modeloIdentificadoTreinoResposta, modeloIdentificadoTreinoIdentificado)


    } catch (err) {
      console.log(err)

    } finally {
      atualizarPagina()

    }

  }
  const apiTreinaResposta = async () => {
    try {
      await postNlpTreinoResposta(modeloTreino, modeloRespostaTreinoResposta, modeloIdentificadoTreinoIdentificado)


    } catch (err) {
      console.log(err)

    } finally {
      atualizarPagina()

    }

  }
  const apiLimpaResposta = async () => {

    try {
      await NlpLimpaRespostas(modeloTreino)


    } catch (err) {
      console.log(err)
    } finally {
      atualizarPagina()
    }

  }
  const modalEstatistica = () => {
    setOpenEstatistica(true)
  }

  return <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'row', backgroundColor: '#f1f1f1', padding: 20 }}>

    <ModalEstatistica openEstatistica={openEstatistica} setOpenEstatistica={setOpenEstatistica} infoModelo={infoModelo}  palavrasChaves={palavrasChaves}/>

    <Autocomplete
      disablePortal
      size="small"
      id="combo-box-demo"
      value={modeloIdentificadoTreinoIdentificado}
      onChange={handleChangeModeloIdentificadoTreinoIdentificado}
      onInputChange={handleChangeModeloIdentificadoTreinoIdentificado}
      options={palavrasChaves && palavrasChaves?.length === 0 ? ["Ainda não tem uma intenção cadastrado."] : palavrasChaves}
      freeSolo  // Isso permite a criação de novas palavras
      sx={{ width: 300, marginRight: 1, borderRadius: 1 }}
      style={{ background: 'white', marginBottom: 10 }}
      renderInput={(params) => <TextField {...params} label='Intenção...' />}
    />

    <Stack

      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <textarea
        type="text"
        placeholder="Pergunta..."
        value={modeloIdentificadoTreinoResposta}
        onKeyDown={(e) => { handleKeyDownPergunta(e) }}
        onChange={handleChangeModeloIdentificadoTreinoResposta}
        style={{ padding: '10px', marginRight: '5px', outline: 'none', borderRadius: 5, height: 40, width: 300, border: '1px solid #c2c2c2' }}
      />
      <Button style={{ padding: '10px', backgroundColor: '#1a5bc4', color: "#fff", fontSize: 10, fontWeight: 600, marginRight: 10 }} size="small"

        onClick={() => {
          apiTreinaPergunta()

        }}>Treinar </Button>

    </Stack>

    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <textarea
        type="text"
        placeholder="Resposta"
        value={modeloRespostaTreinoResposta}
        onKeyDown={(e) => { handleKeyDownResposta(e) }}
        onChange={handleChangeModeloRespostaTreinoResposta}
        style={{ padding: '10px', marginRight: '5px', outline: 'none', borderRadius: 5, height: 40, width: 300, border: '1px solid #c2c2c2' }}
      />
      <Button style={{ padding: '10px', backgroundColor: '#128C7E', color: "#fff", fontSize: 10, fontWeight: 600 }}
        size="small"
        onClick={() => {
          apiTreinaResposta()


        }}>
        Treinar
      </Button>

      <Tooltip title={"Limpa a resposta de todos os identificador"} placement="top-start">
        <SpeakerNotesOffIcon style={{ color: "#075E54", cursor: 'pointer', width: 30, height: 30, marginLeft: 50 }} onClick={() => {
          apiLimpaResposta()

        }}
        />

      </Tooltip>


      <Tooltip title={"Estatisticas do modelo treinado"} placement="top-start">
        <QueryStatsSharpIcon style={{ color: "#cf6d04", cursor: 'pointer', width: 30, height: 30, marginLeft: 50 }} onClick={() => {
          modalEstatistica()
        }}
        />

      </Tooltip>



    </Stack>




  </div>
}